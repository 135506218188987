import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useState } from "react";
import Image from "../../media/images/198034-Tableview.jpg";
import {
  Box,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const pages = ["Home", "Contact Us"];

const NavBar = () => {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);

  const theme = useTheme();

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar
      position="static"
      color="primary"
      sx={{
        backgroundImage: `url(${Image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPositionX: "center",
        backgroundPositionY: "center",
        height: 200,
      }}
    >
      <Container
        className="content-center"
        maxWidth={false}
        sx={{
          backgroundColor: "rgb(0 0 0 / 50%)",
          height: 200,
        }}
      >
        <Toolbar>
          <Typography
            variant="h2"
            component="a"
            href="/"
            sx={{
              display: { xs: "none", md: "flex", fontWeight: "500" },
            }}
          >
            Parklands Doctors
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  href={"/"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    display: "block",
                    fontSize: 20,
                    width: "100%",
                    textDecoration: "none",
                  }}
                >
                  Home
                </Link>
              </MenuItem>
              <MenuItem onClick={handleCloseNavMenu}>
                <Link
                  href={"/contactus"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    display: "block",
                    fontSize: 20,
                    width: "100%",
                    textDecoration: "none",
                  }}
                >
                  Contact Us
                </Link>
              </MenuItem>
            </Menu>
          </Box>

          <Typography
            noWrap
            sx={{
              mr: 2,
              display: { xs: "flex", md: "none" },
              fontSize: { xs: 25, sm: 50 },
              flexGrow: 1,
              fontWeight: "500",
            }}
          >
            Parklands Doctors
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              justifyContent: "end",
              marginTop: 2,
              gap: 5,
            }}
          >
            <Link
              href={"/"}
              onClick={handleCloseNavMenu}
              sx={{
                color: "white",
                display: "block",
                fontSize: 25,
                borderRadius: 1,
                padding: 1,
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              Home
            </Link>
            <Link
              href={"/contactus"}
              onClick={handleCloseNavMenu}
              sx={{
                color: "white",
                display: "block",
                fontSize: 25,
                borderRadius: 1,
                padding: 1,
                textDecoration: "none",
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
              }}
            >
              Contact Us
            </Link>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default NavBar;
