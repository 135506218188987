import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";

interface formObject {
  name: string;
  surname: string;
  email: string;
  message: string;
  phone: string;
}

const ContactUs = () => {
  const [isSent, setIsSent] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit: SubmitHandler<any> = (data) => {
    setIsLoading(true);
    const postParameters = buildPostParameters(data);

    const requestOptions = {
      method: "POST",
      headers: { Authorization: `Basic ${process.env.REACT_APP_MAILGUN_KEY}` },
    };

    fetch(
      `${process.env.REACT_APP_MAILGUN_ENDPOINT}${postParameters}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        toast.success("Message Sent");
        setIsSent(true);
      })
      .catch((error) => {
        toast.error("Error, please try again later.");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const buildPostParameters = (data: formObject) => {
    let paramters = `?from=${data.email}`;
    paramters += `&to=michiel.koortzen@gmail.com&to=parklandsDoctors@gmail.com`;
    paramters += `&subject=${data.name} ${data.surname} ${data.phone}`;
    paramters += `&text=${data.message}`;
    paramters += `&template=Parklands Doctors`;
    paramters += `&h:X-Mailgun-Variables={"name": "${data.name}", "surname": "${data.surname}", "email": "${data.email}", "phone": "${data.phone}", "message": "${data.message}"}`;

    return paramters;
  };

  const theme = useTheme();

  return (
    <>
      <Card className="shadow-2xl">
        <CardHeader
          sx={{
            backgroundColor: theme.palette.primary.main,
          }}
        />
        <CardContent>
          <Box>
            <Box className="flex justify-center content-center">
              <Typography
                variant="h3"
                className="text-center ml-3"
                color={"#666666"}
              >
                Contact Us
              </Typography>
            </Box>

            <Typography
              variant="body1"
              className="text-center"
              color={"#666666"}
            >
              If you have a general query or want to request an appointment,
              send us a message. We'll get back to you as soon as we can.{" "}
              <br></br>
              Alternatively, send us a Whatsapp or give us a call.
            </Typography>

            <form className="mt-5" onSubmit={handleSubmit(onSubmit)}>
              <Box className="grd grid-cols-1 gap-8">
                <Box className="grid grid-cols-1 md:grid-cols-2 gap-8">
                  <Box>
                    <TextField
                      type="text"
                      label="Name"
                      variant="outlined"
                      {...register("name", { required: "Name is required*" })}
                      fullWidth={true}
                    />
                    {errors.name && (
                      <span style={{ color: "red" }}>
                        {errors.name.message?.toString()}
                      </span>
                    )}
                  </Box>
                  <Box>
                    <TextField
                      type="text"
                      label="Surname"
                      variant="outlined"
                      fullWidth={true}
                      {...register("surname", {
                        required: "Surname is required*",
                      })}
                    />
                    {errors.surname && (
                      <span style={{ color: "red" }}>
                        {errors.surname.message?.toString()}
                      </span>
                    )}
                  </Box>
                </Box>
                <Box className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
                  <Box>
                    <TextField
                      type="text"
                      label="Email"
                      variant="outlined"
                      fullWidth={true}
                      {...register("email", {
                        required: "Email is required*",
                        pattern: {
                          value: /^\S+@\S+$/i,
                          message: "Please enter a valid email address*",
                        },
                      })}
                    />
                    {errors.email && (
                      <span style={{ color: "red" }}>
                        {errors.email.message?.toString()}
                      </span>
                    )}
                  </Box>
                  <Box>
                    <TextField
                      type="text"
                      label="Phone number"
                      variant="outlined"
                      fullWidth={true}
                      {...register("phone", {
                        required: "Phone number is required*",
                      })}
                    />
                    {errors.phone && (
                      <span style={{ color: "red" }}>
                        {errors.phone.message?.toString()}
                      </span>
                    )}
                  </Box>
                </Box>
                <Box className="mt-8">
                  <TextField
                    type="text"
                    label="Message"
                    variant="outlined"
                    fullWidth={true}
                    multiline
                    rows={4}
                    {...register("message", {
                      required: "Message is required*",
                    })}
                  />
                  {errors.message && (
                    <span style={{ color: "red" }}>
                      {errors.message.message?.toString()}
                    </span>
                  )}
                </Box>
                <Box className="mt-8">
                  <Button
                    type="submit"
                    variant="contained"
                    onClick={() => {
                      console.log(errors);
                    }}
                    disabled={isSent || isLoading}
                    sx={{
                      width: 150,
                    }}
                  >
                    {isLoading ? (
                      <>
                        <CircularProgress size={24} />
                      </>
                    ) : (
                      <>Send Message</>
                    )}
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>
      <Toaster />
    </>
  );
};

export default ContactUs;
