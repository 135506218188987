import {
  Box,
  Card,
  CardContent,
  CardHeader,
  useTheme,
  Typography,
  SvgIcon,
} from "@mui/material";
import MonitorHeartIcon from "@mui/icons-material/MonitorHeart";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import MedicationIcon from "@mui/icons-material/Medication";
import FemaleIcon from "@mui/icons-material/Female";
import PsychologyIcon from "@mui/icons-material/Psychology";
import MasksIcon from "@mui/icons-material/Masks";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import DescriptionIcon from "@mui/icons-material/Description";
import CoronavirusIcon from "@mui/icons-material/Coronavirus";
import Image from "../media/images/198034-Tableview.jpg";

const Dashboard = () => {
  const items = [
    {
      name: "Proactive Healthcare",
      description: "Routine checkups, health assessments and medical advice",
      icon: MedicalInformationIcon,
    },
    {
      name: "Diagnosis and Treatment",
      description:
        "Identifying and managing common, acute illnesses for all ages",
      icon: CoronavirusIcon,
    },
    {
      name: "Chronic Diseases",
      description:
        "Comprehensive screening and holistic management of high blood pressure, diabetes, asthma and high cholesterol",
      icon: MedicationIcon,
    },
    {
      name: "Women's Health",
      description:
        "Breast cancer screenings, pap smears, hormone replacement therapy and family planning",
      icon: FemaleIcon,
    },
    {
      name: "Mental Health and Wellbeing",
      description:
        "Screening and management of common psychological conditions such as depression and anxiety",
      icon: PsychologyIcon,
    },
    {
      name: "Minor surgical procedures",
      description:
        "Removal of skin lesions, abscess drainage and stitching of open wounds.  Insertion of long acting reversible contraception devices, placed under the skin, offering peace of mind for the next 3 years ",
      icon: MasksIcon,
    },
    {
      name: "Micronutrient and \nVitamin Supplementation",
      description:
        "IV immune and energy boosters as well as infusions for skin, nail and hair health",
      icon: VaccinesIcon,
    },
    {
      name: "Medical Documents",
      description:
        "Completion of professional drivers permits and medical insurance forms",
      icon: DescriptionIcon,
    },
  ];

  const theme = useTheme();

  return (
    <>
      <Box className="grid lg:grid-cols-4 md:grid-cols-2 sm:grid-cols-1 gap-8">
        {items.map((item, i) => (
          <Card className="shadow-2xl">
            <CardHeader
              sx={{
                backgroundColor: theme.palette.primary.main,
              }}
            />
            <CardContent>
              <Box display="flex" justifyContent="center">
                <SvgIcon
                  color="warning"
                  component={item.icon}
                  inheritViewBox
                  sx={{
                    fontSize: 80,
                  }}
                />
              </Box>
              <Box display="flex" justifyContent="center" textAlign={"center"}>
                <Typography
                  sx={{ fontSize: 22 }}
                  fontWeight="bold"
                  whiteSpace={"pre-wrap"}
                >
                  {item.name}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="center">
                <Typography
                  sx={{ fontSize: 20 }}
                  variant="body1"
                  textAlign="center"
                >
                  {item.description}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box
        sx={{
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionX: "center",
          backgroundPositionY: "top",
          marginTop: 5,
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        <Box sx={{ backgroundColor: "rgb(0 0 0 / 50%)" }}>
          <Box
            className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-8 lg:p-10 sm:p-10 p-5"
            sx={{
              borderRadius: 3,
            }}
          >
            <Box className="lg:col-span-2 md:col-span-1 sm:col-span-1 content-center text-center">
              <Typography sx={{ fontWeight: "500" }}>
                <span className="xl:text-[100px] lg:text-[80px] md:text-[60px] sm:text-[50px] text-[50px] text-white italic">
                  From Children <br></br> to the Elderly, <br></br>Healthcare
                  for the Whole Family
                </span>
              </Typography>
            </Box>
            <Box
              sx={{
                backgroundColor: "rgb(0 0 0 / 50%)",
                borderRadius: 3,
                padding: 4,
              }}
            >
              <Typography variant="h5" sx={{ color: "white" }}>
                <b>Dr Michiel Koortzen-Coetzee</b> received his Bachelor’s in
                Medicine and Surgery at the University of Pretoria in 2020.
                <br></br> <br></br>
                During his studies, he created the Tuks Emergency Medicine
                Student Society to further improve training in critical
                lifesaving skills. Through the Society, he lead his team to
                place 4th in an International Emergency Medicine competition in
                Thailand. <br></br> <br></br> He went on to complete his
                Internship and Community Service at Tygerberg Tertiary Hospital
                and Ermelo Regional Hospital, respectively. After spending his
                Community Service year in Critical Care and Anaesthesia, he
                realised the importance of treating chronic diseases effectively
                as well as the value of comprehensive, holistic and
                multi-disciplinary patient care.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
