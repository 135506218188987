import { Routes, Route } from "react-router-dom";
import Layout from "./components/layout/layout";
import Dashboard from "./pages/dashboard";
import ContactUs from "./pages/contactUs";

const App = () => {
  return (
    <>
      <Layout>
        <Routes>
          <Route path="" element={<Dashboard />} />
          <Route path="/contactus" element={<ContactUs />} />
          {/* <Route path="/products" element={<Products />} />
           <Route path="/about" element={<About />} /> */}
        </Routes>
      </Layout>
    </>
  );
};

export default App;
