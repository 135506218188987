import { createTheme } from "@mui/material";

const colors = {
  primary: "#38761d",
  secondary: "#05668d",
  error: "#F21B3F",
  warning: "#80b918",
  info: "#08bdbd",
  success: "#ff5714",
};

const DefaultTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    error: {
      main: colors.error,
    },
    warning: {
      main: colors.warning,
    },
    info: {
      main: colors.info,
    },
    success: {
      main: colors.success,
    },
    background: {
      default: "#FFFFFF",
    },
  },
  typography: {
    fontFamily: '"Nunito", normal',
  },
  components: {
    // Name of the component
    MuiCard: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow:
            "var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "&:hover fieldset": {
            borderColor: colors.primary + "!important",
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          fontFamily: "Nunito" + "!important",
          transition: "0.5s all ease",
        },
      },
    },
  },
});

export default DefaultTheme;
