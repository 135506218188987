import { Box, Container, Divider } from "@mui/material";
import { FC, ReactNode } from "react";
import NavBar from "./appbar";
import Footer from "./footer";

interface LayoutProps {
  children?: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Box
        sx={{
          bgcolor: "background.default",
          color: "text.primary",
        }}
      >
        <NavBar />
        <Container
          maxWidth={false}
          sx={{
            maxWidth: "100vw",
            marginTop: 3,
            marginBottom: 10,
            minHeight: "calc(90vh - 200px)",
          }}
        >
          {children}
        </Container>
        <Footer />
      </Box>
    </>
  );
};

export default Layout;
