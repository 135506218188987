import { AppBar, Box, Container, Typography } from "@mui/material";
import moment from "moment";
import { useTheme } from "@mui/material";

const Footer = () => {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          bottom: 0,
          position: "static",
          width: "100%",
          backgroundColor: theme.palette.primary.main,
          color: "white",
        }}
      >
        <Container maxWidth={false}>
          <Box className="grid grid-cols-1 md:grid-cols-4 min-h-10 items-center gap-8 p-5">
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Opertaing Hours</Typography>
              <Typography variant="body1">
                Monday - Friday from 8am - 5pm
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Address</Typography>
              <Typography>
                Morningfield Spar, 74 Parklands Main Rd, Parklands, 7441
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Phone</Typography>
              <Typography>021 879 2441</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">Whatsapp</Typography>
              <Typography>078 526 0318</Typography>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
